import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company, CompanyStatus, CompanyStatusEvent } from '../interfaces/company.model';
import { Worker, WorkerStatus, WorkerStatusEvent } from '../interfaces/worker.model';
import { RoleType } from '../interfaces/role.model';
import { StatoEnum } from '../enum/stato.enum';
import { Route } from '@angular/router';

@Pipe({ name: 'castWorkerUser' })
export class CastWorkerUserPipe implements PipeTransform {
  transform<S>(value: S): Worker {
    return value as unknown as Worker;
  }
}
@Pipe({ name: 'castCompanyUser' })
export class CastCompanyUserPipe implements PipeTransform {
  transform<S>(value: S): Company {
    return value as unknown as Company;
  }
}
@Pipe({ name: 'castWorkerCompanyUser' })
export class CastWorkerCompanyUserPipe implements PipeTransform {
  transform<S>(value: S): Worker | Company {
    return value as unknown as Worker | Company;
  }
}

interface AppStorage {
  userRoleInfo: UserRoleInfo;
}

export interface Impersonator {
  userUuid: string;
  username: string;
  impersonator?: Impersonator;
}

export interface Identity {
  userUuid: string;
  username: string;
  roles: string[];
  impersonator?: Impersonator;
}

export interface UserRoleInfo {
  role: RoleType;
  stato: StatoEnum;
}

export interface UserWorkerStatus {
  status: WorkerStatus;
}
export interface UserCompanyStatus {
  status: CompanyStatus;
}



@Injectable()
export class UserService {
  private _appStorage: AppStorage;
  private requestedRoute: any
  private identity: BehaviorSubject<Identity> = new BehaviorSubject(null);
  identity$ = this.identity.asObservable();
  private user: BehaviorSubject<Company | Worker | Identity> = new BehaviorSubject(null);
  user$ = this.user.asObservable();
  private proposalsNumber: BehaviorSubject<number> = new BehaviorSubject(0);
  proposalsNumber$ = this.proposalsNumber.asObservable();
  private notificationsNumber: BehaviorSubject<number> = new BehaviorSubject(0);
  notificationsNumber$ = this.notificationsNumber.asObservable();
  constructor(private apiClient: ApiClient) {
    this.identity.next(JSON.parse(localStorage.getItem('identity')));
  }

  setRequestedRoute(requestedRoute, queryParams=null){
    this.requestedRoute = [requestedRoute, queryParams]
  }

  getRequestedRoute(clean = false){
    let requestedRoute = this.requestedRoute
    if(clean)
      this.requestedRoute = null
    return requestedRoute
  }

  deleteRequestedRoute(){
    this.requestedRoute = null
  }

  getIdentity(): Identity {
    return this.identity.value;
  }

  setIdentityFromClaims(claims: { [x: string]: any; }) {
    function mapImpersonator(cs) {
      if (!cs) {
        return null;
      }
      return {
        userUuid: cs.sub,
        username: cs.username,
        impersonator: mapImpersonator(cs.impersonator),
      };
    }
    const value = {
      userUuid: claims.sub,
      username: claims.username,
      roles: claims.roles,
      impersonator: mapImpersonator(claims.impersonator),
    };
    localStorage.setItem('identity', JSON.stringify(value));
    this.identity.next(value);
  }

  saveUserRoleInfo(userData: UserRoleInfo): void {
    const storageAuthUserInfo = JSON.parse(
      localStorage.getItem('auth_user_role_info')
    );

    this._appStorage = {
      ...storageAuthUserInfo,
      userRoleInfo: userData,
    };

    localStorage.setItem(
      'auth_user_role_info',
      JSON.stringify(this._appStorage)
    );
  }

  get appStorage(): AppStorage {
    if (!this._appStorage) {
      const appUserInfo: any = localStorage.getItem('auth_user_role_info');
      if (appUserInfo) {
        this._appStorage = JSON.parse(appUserInfo);
      }
    }
    return this._appStorage;
  }

  get userRoleInfo(): UserRoleInfo {
    return this.appStorage?.userRoleInfo || null;
  }

  getUserRoleInfo(): Observable<UserRoleInfo> {
    return this.apiClient.request('getUserStatus');
  }

  setUser(user: Company | Worker | Identity) {
    this.user.next(user);
  }

  getUser(): Company | Worker | Identity {
    return this.user.value;
  }

  setProposalsNumber(number: number) {
    this.proposalsNumber.next(number);
  }

  setNotificationsNumber(number: number) {
    this.notificationsNumber.next(number);
  }

  getNotificationsNumber(): number {
    return this.notificationsNumber.value;
  }

  getRiskRegistry() {
    return this.apiClient.request('getRiskRegistry');
  }

  saveRiskRegistry(params) {
    return this.apiClient.request('saveRiskRegistry', params);
  }

  deleteUser(): Observable<any> {
    return this.apiClient.request('deleteWorker');
  }

  getUserWorkerStatus(): Observable<UserWorkerStatus> {
    return this.apiClient.request('getUserWorkerStatus');
  }

  getUserCompanyStatus(): Observable<UserCompanyStatus> {
    return this.apiClient.request('getUserCompanyStatus');
  }

  getUserStatusEvents(): WorkerStatusEvent[] | CompanyStatusEvent[] {
    if (this.userRoleInfo.role === RoleType.WORKER) {
      return (this.getUser() as Worker).statusEvents;
    } else {
      return (this.getUser() as Company).statusEvents;
    }
  }

  getConversationListWorker() {
    return this.apiClient.request('getConversationListWorker');
  }

  getConversationMessagesWorker(conversationId: number) {
    return this.apiClient.request('getConversationMessagesWorker', null, null, {
      conversationId,
    });
  }

  sendMessageWorker(conversationId: number, message: string) {
    return this.apiClient.request('sendMessageWorker', { text: message }, null, {
      conversationId,
    });
  }

  getLastMessagesWorker(conversationId: number, messageId: number) {
    return this.apiClient.request('getLastMessagesWorker', null, null, {
      conversationId, messageId
    });
  }

  onLogout() {
    this.user.next(null);
  }
}
