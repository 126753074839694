import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(private authService: NbAuthService, private router: Router, private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._checkActivation(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._checkActivation(route, state);
  }

  private _checkActivation(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap((isAuth: boolean) => {
        if (!isAuth) {
          if(state.root.queryParams){
            this.userService.setRequestedRoute(state.url.split("?")[0].split("/"), { queryParams: state.root.queryParams })
          }else{
            this.userService.setRequestedRoute(state.url.split("/"))
          }
          
          this.router.navigate(['auth', 'login']);
        }else{
          const announcementId = localStorage.getItem('announcementId')
          if (announcementId) {
            this.router.navigate(
              [
                'private',
                'candidato',
                'cerca-annunci',
                'search-detail',
                announcementId
              ])
          }
        }
      })
    );
  }
}
