export enum ContractStatus {
    PROPOSED = "PROPOSED",
    REJECTED = "REJECTED",
    WORKER_ACCEPTED = "WORKER_ACCEPTED",
    CLIENT_CONTRACT_CREATION_REQUESTED = "CLIENT_CONTRACT_CREATION_REQUESTED",
    CLIENT_CONTRACT_CREATED = "CLIENT_CONTRACT_CREATED",
    WORKER_CONTRACT_CREATION_REQUESTED = "WORKER_CONTRACT_CREATION_REQUESTED",
    WORKER_CONTRACT_CREATED = "WORKER_CONTRACT_CREATED",
    MUTUALLY_SIGNED = "MUTUALLY_SIGNED",
    CLIENT_CONTRACT_CREATION_FAILED = "CLIENT_CONTRACT_CREATION_FAILED",
    CLIENT_CONTRACT_SIGNATURE_CANCELLED = "CLIENT_CONTRACT_SIGNATURE_CANCELLED",
    WORKER_CONTRACT_CREATION_FAILED = "WORKER_CONTRACT_CREATION_FAILED",
    WORKER_CONTRACT_SIGNATURE_CANCELLED = "WORKER_CONTRACT_SIGNATURE_CANCELLED",
    EXPIRED = "EXPIRED",
    EXPIRED_WITH_CLIENT_CONTRACT_SIGNED = "EXPIRED_WITH_CLIENT_CONTRACT_SIGNED"
}