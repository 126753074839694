<ng-container>
  <app-profile-menu
    [userType]="userType"
  ></app-profile-menu>
  <br />
  <div
    class="title mx-5 mt-4 d-flex justify-content-center align-items-center"
  >
    <h3 class="m-0">
      {{ 'PRIVATE.COMMON.AVAILABILITY.TITLE' | translate }}
    </h3>
  </div>
  <div class="mx-5">       
    <div class="mx-5 mb-4">
      <div class="interview-list-container">
        <div class="row" *ngIf="!renewalSuccess && !extensionSuccess">
          <div class="col-12">
            <div class="card-light my-4">
              <div class="card-body overflow-auto">
                <app-availability-table
                  [userType]="userType"
                  [dataSource]="dataSource"
                  [pageSize]="pageSize"
                  (onChangePage)="applyFilters($event)"
                ></app-availability-table>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>      
</ng-container>