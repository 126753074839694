import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserType } from '../../../../../core/interfaces/user-type.enum';
import { OverlayService } from '../../../overlay/overlay.service';
import { PixelCompanyLandingBService } from './pixel-company-landing-b.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-landing-b',
  templateUrl: './company-landing-b.component.html',
  styleUrls: ['./company-landing-b.component.scss'],
})
export class CompanyLandingBComponent implements OnInit {
  UserType = UserType;
  userType: UserType = UserType.COMPANY;
  store = environment.store;
  heroImg = Math.floor(Math.random() * (7 - 1 + 1)) + 1;

  constructor(
    private overlayService: OverlayService,
    private pixelCompanyService: PixelCompanyLandingBService,
    private router: Router
  ) {}

  openLoginOverlay() {
    this.overlayService.generalLoginOverlayOpen();
  }

  ngOnInit() {
    this.pixelCompanyService.load();
  }

  toRegister() {
    this.router.navigate(['auth/register/general'], {
      queryParams: { userType: UserType.COMPANY },
    });
  }
}
