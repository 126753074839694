export type ApiPageableRequestContructor = Omit<ApiPageableRequest, 'toServer'>;

export class ApiPageableRequest {
  pageNumber: number;
  pageSize: number;
  sort?: ApiSortRequest[];

  constructor(options: ApiPageableRequestContructor) {
    this.pageNumber = options.pageNumber;
    this.pageSize = options.pageSize;
    this.sort = options.sort;
  }

  toServer(): { pageNumber: string; pageSize: string; sort?: string } {
    const sort = this.sort[0];

    const toServerObj: any = {
      pageNumber: (this.pageNumber - 1).toString(),
      pageSize: this.pageSize.toString(),
    };

    if (sort) {
      toServerObj.sort = `${sort.field},${sort.direction}`;
    }

    return toServerObj;
  }
}

export interface ApiSortRequest {
  direction: 'desc' | 'asc';
  field: string;
}
