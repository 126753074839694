import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NbAccessChecker } from '@nebular/security';
import { TuiDialogService } from '@taiga-ui/core';
import { Observable, Subject, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CompleteClientDialogComponent } from 'src/app/shared/components/complete-client-dialog/complete-client-dialog.component';
import { CompleteWorkerDialogComponent } from 'src/app/shared/components/complete-worker-dialog/complete-worker-dialog.component';
import { CompleteProfileDialogComponent } from 'src/app/shared/components/complete-profile-dialog/complete-profile-dialog.component';
import { UserService } from '../services/user.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { RoleType } from '../interfaces/role.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private accessChecker: NbAccessChecker,
    private dialogService: TuiDialogService,
    private i18n: TranslateService,
    private injector: Injector,
    private routerService: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._checkActivation(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._checkActivation(route, state);
  }

  private _checkActivation(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let permission = 'view';
    let resource: string;

    //Check on UserStatusEvent
    if(route.data.event !== undefined){
      return this.checkStatusEvent(route.data.event).pipe(
        tap((authorized) => {
          if(!authorized){
            this.dialogService.open<number>(
              new PolymorpheusComponent(CompleteProfileDialogComponent, this.injector),
              {
                dismissible: true,
                size: 's',
                label: this.i18n.instant('PRIVATE.UTILITY.STATUS_EVENT_DIALOG.TITLE'),
              }
            ).subscribe({complete: () => {}});       
          }
        }),
        take(1)
      );
    }

    if (route.data.permission !== undefined) {
      permission = route.data.permission;
    }
    if (!route.data.resource !== undefined) {
      resource = route.data.resource;
    }

    if (!resource) {
      throw new Error('AuthGuard: resource not found');
    }

    return this.accessChecker.isGranted(permission, resource).pipe(
      tap((authorized) => {
        if (!authorized) {
          const dialog = this.dialogService.open<number>(
            new PolymorpheusComponent(
              this.getDialogComponent(this.userService.userRoleInfo.role as RoleType),
              this.injector
            ),
            {
              dismissible: true,
              size: 's',
              label: this.i18n.instant(
                this.getDialogTitle(this.userService.userRoleInfo.role as RoleType)
              ),
            }
          );
          dialog.subscribe({
            complete: () => {
              // delete announcementId if complete-profile dialog closed #MULTIPOSTING3
              if (localStorage.getItem('announcementId')) {
                localStorage.removeItem('announcementId');
              }
            },
          });
        } else {
          // delete announcementId if profile completed #MULTIPOSTING2
          if (localStorage.getItem('announcementId')) {
            localStorage.removeItem('announcementId');
          }
        }
      }),
      take(1)
    );
  }

  checkStatusEvent(event: string): Observable<boolean> {
    let userEvents: string[] = this.userService.getUserStatusEvents();
    let accessGranted = userEvents.includes(event);    
    return of(accessGranted);
  }

  getDialogComponent(role: RoleType): any {
    return role === RoleType.COMPANY || role === RoleType.DELEGATE
      ? CompleteClientDialogComponent
      : CompleteWorkerDialogComponent;
  }

  getDialogTitle(role: RoleType) {
    return role === RoleType.COMPANY || role === RoleType.DELEGATE
      ? 'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.TITLE'
      : 'PRIVATE.WORKER.COMPLETE_PROFILE.DIALOG.TITLE';
  }
}
