import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientService} from 'src/app/api-client.service';
import { Observable } from 'rxjs';
import { InterviewListItem, InterviewRequest } from '../interfaces/interview.model';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { TuiDay } from '@taiga-ui/cdk';

@Injectable()
export class AvailabilityService {
  constructor(
    private apiClient: ApiClientService,
    private httpClient: HttpClient) {}

    getAvailabilityListCompany(data): Observable<any[]> {
      return this.apiClient.request('getAvailabilityCompanyList', data);
    }
  
    getAvailabilityListWorker(data): Observable<any[]> {
      return this.apiClient.request('getAvailabilityWorkerList', data);
    }    

    getAvailabilityDetailCompany(id: number): Observable<any[]> {
      return this.apiClient.request('getAvailabilityCompanyDetail', null, null, { id: id });
    }
  
    getAvailabilityDetailWorker(id): Observable<any[]> {
      return this.apiClient.request('getAvailabilityWorkerDetail', null, null, { id: id });
    }   

    acceptAvailabilityNew(id): Observable<any> {
      return this.apiClient.request('acceptAvailabilityNew', null, null, { id: id });
    }

    acceptAvailabilityExtension(id): Observable<any> {
      return this.apiClient.request('acceptAvailabilityExtension', null, null, { id: id });
    }

    acceptAvailabilityRenewal(id): Observable<any> {
      return this.apiClient.request('acceptAvailabilityRenewal', null, null, { id: id });
    }

    rejectAvailabilityNew(request): Observable<any> {
      return this.apiClient.request('rejectAvailabilityNew', request);
    }

    rejectAvailabilityExtension(request): Observable<any> {
      return this.apiClient.request('rejectAvailabilityExtension', request);
    }

    rejectAvailabilityRenewal(request): Observable<any> {
      return this.apiClient.request('rejectAvailabilityRenewal', request);
    }


}
