import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/auth/services/user.service';
import { ICON_TYPE } from 'src/app/shared/notifications/enum/icon-type';
import { NotificationsService } from 'src/app/shared/notifications/services/notifications.service';
import { Notification } from 'src/app/shared/notifications/interfaces/notification';
import { UserType, role2route } from 'src/app/core/auth/interfaces/role.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationState } from 'src/app/shared/notifications/enum/notification-state.enum';
import { PaggingRequestParams } from 'src/app/core/interfaces/pagging-request-params';

@UntilDestroy()
@Component({
  selector: 'app-notifications-and-messages',
  templateUrl: './notifications-and-messages.component.html',
  styleUrls: ['./notifications-and-messages.component.scss'],
})
export class NotificationsAndMessagesComponent implements OnInit {
  newNotifications: Notification[];
  notificationsNumber: number = 0;
  messagesNumber: number = 0;
  iconTypeMap = ICON_TYPE;
  userType: UserType;
  get UserTypeEnum() {
    return UserType;
  }
  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.newNotifications = this.notificationsService.newNotifications;
    this.userType = role2route(this.userService.userRoleInfo.role)
    this.userService.proposalsNumber$.subscribe((value) => {
      if (value) {
        this.messagesNumber = value <= 99 ? value : 99;
      } else if (value === 0) {
        this.messagesNumber = 0;
      }
    });
    this.userService.notificationsNumber$.subscribe((value) => {
      if (value) {
        this.notificationsNumber = value <= 99 ? value : 99;
      } else if (value === 0) {
        this.notificationsNumber = 0;
      }
    });
  }

  goToNotifications = () => {
    this.router.navigate(['private', this.userType, 'notifications']);
  };
  goToMessages() {
    this.router.navigate(['private', this.userType, 'availability', 'availability-list']);
  }
  getPath(iconType: string): string {
    if (iconType in this.iconTypeMap) {
      return `/private/${this.userType}${this.iconTypeMap[iconType].path}`;
    }
    return `/private/${this.userType}/notifications`;
  }

  updateNotifStatus(uuid: string) {
    this.notificationsService
      .updateNotificationStatus(uuid)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params: PaggingRequestParams = {
          page: 0,
          size: 2,
        };
        this.notificationsService.getNotificationsNumber().subscribe();
        this.notificationsService
          .getNotifications(params, NotificationState.NEW)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.notificationsService.newNotifications = res.content;
            this.newNotifications = res.content;
          });
        this.notificationsService
          .getNotifications(params, NotificationState.OLD)
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.notificationsService.oldNotifications = res.content;
          });
      });
  }
}
