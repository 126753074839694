import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { AvailabilityService } from 'src/app/shared/services/availability.service';
import { ActivatedRoute, Router } from '@angular/router';


@UntilDestroy()
@Component({
  selector: 'app-availability-detail',
  templateUrl: './availability-detail.component.html',
  styleUrls: ['./availability-detail.component.scss'],
})
export class AvailabilityDetailComponent implements OnInit {  
  @Input() userType: UserType;
  @Output() onAccept: EventEmitter<any> = new EventEmitter();
  @Output() onReject: EventEmitter<any> = new EventEmitter();

  availabilityDetail: any;
  constructor(private route: ActivatedRoute, private router: Router, private availabilityService: AvailabilityService){}
 
  ngOnInit(): void {   
    this.route.params.subscribe(e => {
      if(e.id){
        if(this.userType == UserType.WORKER)
          this.availabilityService.getAvailabilityDetailWorker(e.id).subscribe(e => this.availabilityDetail = e)
        else
          this.availabilityService.getAvailabilityDetailCompany(e.id).subscribe(e => this.availabilityDetail = e)
      }
    })
  }

  goBack(){
    let back = ["private","candidato","availability","availability-list"]
    if(this.userType == UserType.COMPANY)
      back = ["private","azienda","availability","availability-list"]

    this.router.navigate(back)
  }
}
