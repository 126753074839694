import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/app/core/auth/interfaces/company.model';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { Worker } from 'src/app/core/auth/interfaces/worker.model';

interface Card {
  title: string;
  number: string;
  link: string | string[];
  urlFragment?: string;
}

@Component({
  selector: 'app-user-profile-actions-section',
  templateUrl: './user-profile-actions-section.component.html',
  styleUrls: ['./user-profile-actions-section.component.scss'],
})
export class UserActionsSectionComponent implements OnInit {
  cards: Card[] = [];

  userType: UserType;
  get UserTypeEnum() {
    return UserType;
  }

  @Input() set user(user: Worker | Company) {
    this.cards = [];
    if (
      'disponibility' in user &&
      'application' in user &&
      'advertisement' in user &&
      'contracts' in user
    ) {
      this.userType = UserType.WORKER;
      this.cards.push({
        number: this.getNumberString(user.advertisement),
        link: ['private', 'candidato', 'annunci'],
        title: 'PRIVATE.WORKER.USER_DETAIL.ANNUNCI_LAVORO',
      });
      this.cards.push({
        number: this.getNumberString(user.application),
        link: ['private', 'candidato', 'annunci'],
        urlFragment: 'applications',
        title: 'PRIVATE.WORKER.USER_DETAIL.CANDIDATURE',
      });
      this.cards.push({
        number: this.getNumberString(user.disponibility),
        link: ['private', 'candidato', 'availability', 'availability-list'],
        title: 'PRIVATE.WORKER.USER_DETAIL.RICHIESTE_DISPONIBILITA',
      });
      this.cards.push({
        number: this.getNumberString(user.contracts),
        link: ['private', 'candidato', 'documenti', 'contract'],
        title: 'PRIVATE.WORKER.USER_DETAIL.CONTRATTI_LAVORO',
      });
    } else {
      this.userType = UserType.COMPANY;
      this.cards.push({
        number: this.getNumberString(user.favouriteCandidatesCount),
        link: ['private', 'azienda', 'annunci', 'candidati-salvati'],
        title:
          'PRIVATE.COMPANY.USER.USER_PROFILE.BOX.FAVOURITE_CANDIDATES.TITLE',
      });
      this.cards.push({
        number: this.getNumberString(user.jobOfferCount),
        link: ['private', 'azienda', 'annunci', 'gestione-annunci'],
        title: 'PRIVATE.COMPANY.USER.USER_PROFILE.BOX.ANNUNCI.TITLE',
      });
      this.cards.push({
        number: this.getNumberString(user.contractCount),
        link: ['private', 'azienda', 'documenti', 'contract'],
        title: 'PRIVATE.COMPANY.USER.USER_PROFILE.BOX.CONTRATTI.TITLE',
      });
      this.cards.push({
        number: this.getNumberString(user.candidateCount),
        link: ['private', 'azienda', 'documenti', 'lavoro'],
        title: 'PRIVATE.COMPANY.USER.USER_PROFILE.BOX.LAVORATORI.TITLE',
      });
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void { }

  navigate(link: string|string[], fragment?: string) {
    var url = typeof link == 'string' ? link as any : link as any[];
    if(fragment) {
      this.router.navigate(url, {fragment});
    } else {
      this.router.navigate(url);
    }
  }

  getNumberString(number: number): string {
    if (number === 0) {
      return '0';
    }
    if (number < 10) {
      return '0' + number;
    } else {
      return number.toString();
    }
  }
}
