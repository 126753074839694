<div class="container-fluid gx-0 ngm">
  <!-- TITLE AND SEARCHBAR -->
  <section class="row welcome mb-5 px-4 px-lg-4 px-xl-5 ngm">
    <div class="col-12 my-2 ngm">
      <h1
        class="title text-start"
        [innerHTML]="'LANDING_PAGE_B.COMPANY.TITLE' | translate | uppercase"
      ></h1>

      <p class="sub-title text-start d-none d-lg-flex" [innerHTML]="'LANDING_PAGE_B.COMPANY.SUB_TITLE' | translate"> </p>
      <p class="sub-title text-start d-flex text-start d-lg-none" [innerHTML]="'LANDING_PAGE_B.COMPANY.SUB_TITLE_ONE_LINE' | translate"></p>
      <!--p class="sub-title-sm text-start d-flex d-lg-none" [innerHTML]="'LANDING_PAGE_B.COMPANY.SUB_TITLE_ONE_LINE' | translate"> </p-->
      
      <h5 class="text-start text-white mt-0 ngm checkbox">
        {{ "LANDING_PAGE_B.COMPANY.CHECK_1" | translate | uppercase }}
      </h5>
      <h5 class="text-start text-white mt-0 ngm checkbox">
        {{ "LANDING_PAGE_B.COMPANY.CHECK_2" | translate | uppercase }}
      </h5>
      <h5 class="text-start text-white mt-0 ngm checkbox">
        {{ "LANDING_PAGE_B.COMPANY.CHECK_3" | translate | uppercase }}
      </h5>
      <h5 class="text-start text-white mt-0 ngm checkbox">
        {{ "LANDING_PAGE_B.COMPANY.CHECK_4" | translate | uppercase }}
      </h5>
      <h5 class="text-start text-white mt-0 ngm checkbox">
        {{ "LANDING_PAGE_B.COMPANY.CHECK_5" | translate | uppercase }}
      </h5>


      <button class="btn btn-green-reverse mt-4 ngm front" (click)="toRegister()">
        {{ "LANDING_PAGE_B.COMPANY.ACTION_4.BUTTON" | translate }}
      </button>
    </div>
    <div class="hero-img-container">
      <img
        [src]="'/assets/images/landing-pages/hero_0' + heroImg + '.png'"
        alt=""
      />
    </div>    
  </section>


  <section class="row why-us">
    <!-- BIGGER SCREENS (LG+)-->
    <div
      class="d-none d-lg-flex action-container why-us justify-content-center align-items-center gap-5 ngm"
    >
      <div class="col-12 col-lg-6 img-container"></div>
      <div class="col-12 col-lg-6 text mb-4 mb-md-4 ngm text-container">
        <h4
          class="title text-start"
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.TITLE' | translate"
        ></h4>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TITLE' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_1" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_1' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_2" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_2' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_3" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_3' | translate"
        ></p>

        <button class="btn btn-green mt-4" (click)="toRegister()">
          {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
        </button>
      </div>
    </div>
    <!-- SMALLER SCREENS (LG+)-->
    <div class="d-flex flex-column d-lg-none card card-action why-us ngm">
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title"
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.TITLE' | translate"
        ></h4>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TITLE' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_1" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_1' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_2" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_2' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_3" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_3' | translate"
        ></p>

        <div class="d-grid">
          <button class="btn-mobile btn-green mt-4 ngm" (click)="toRegister()">
            {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- COME FUNZIONA -->
  <section class="row how-it-works">
    <div class="row col-12">
      <app-how-it-works-landing-b></app-how-it-works-landing-b>
    </div>
    <!--div class="col-1"></div-->
    <div class="row col-10 offset-1 d-grid d-lg-none" align="center">
      <button class="btn-mobile btn-green mt-4 ngm" (click)="toRegister()">
        {{ "LANDING_PAGE_B.COMPANY.ACTION_1.BUTTON" | translate }}
      </button>
    </div>

    <div class="d-lg-block row col-12 padding-top d-grid d-none" align="center">
      <button class="btn btn-green mt-4 ngm" style="max-width:130px" (click)="toRegister()">
        {{ "LANDING_PAGE_B.COMPANY.ACTION_1.BUTTON" | translate }}
      </button>
    </div>
  </section>

  <!-- ACTION 1 - CREA ANNUNCIO -->
  <section class=" action-1 row">
    <!-- BIGGER SCREENS (LG+)-->
    <div class=" d-none d-lg-flex ngm card card-action card-action-1">
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE_B.COMPANY.ACTION_1.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_1.TEXT_1" | translate }}
        </p>
        <p
          class="card-subtitle mt-1 ngm text-start"
          [innerHTML]="'LANDING_PAGE_B.COMPANY.ACTION_1.TEXT_2' | translate"
        ></p>
        <button class="btn btn-green mt-4 ngm" (click)="toRegister()">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_1.BUTTON" | translate }}
        </button>
      </div>
      
    </div>
    <!-- SMALLER SCREENS -->
    <div
      class="d-flex d-lg-none flex-column card card-action card-action-1 mx-auto ngm"
    >
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE_B.COMPANY.ACTION_1.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_1.TEXT_1" | translate }}
        </p>
        <p
          class="card-subtitle mt-1 ngm text-start"
          [innerHTML]="'LANDING_PAGE_B.COMPANY.ACTION_1.TEXT_2' | translate"
        ></p>
        <div class="d-grid">
          <button
            class="btn-mobile btn-green mt-4 ngm"
            (click)="toRegister()"
          >
            {{ "LANDING_PAGE_B.COMPANY.ACTION_4.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="search row">
    <!-- BIGGER SCREENS (LG+)-->
    <div class="card-search col-12 ">
      <app-search-campaign-b [userType]="userType"></app-search-campaign-b>
    </div>
  </section>

  <section class=" action-2 row">
    <!-- BIGGER SCREENS (LG+)-->
    <div class=" d-none d-lg-flex ngm card card-action card-action-2">
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE_B.COMPANY.ACTION_2.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_2.TEXT_1" | translate }}
        </p>
        <button class="btn btn-green mt-4 ngm" (click)="toRegister()">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_2.BUTTON" | translate }}
        </button>
      </div>
      <div class="img-container"></div>
    </div>
    <!-- SMALLER SCREENS -->
    <div
      class="d-flex d-lg-none flex-column card card-action card-action-2 mx-auto ngm"
    >
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE_B.COMPANY.ACTION_2.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE_B.COMPANY.ACTION_2.TEXT_1" | translate }}
        </p>
        <div class="d-grid">
          <button
            class="btn-mobile btn-green mt-4 ngm"
            (click)="toRegister()"
          >
            {{ "LANDING_PAGE_B.COMPANY.ACTION_4.BUTTON" | translate }}
          </button>
        </div>

      </div>
      
    </div>
  </section>

  <!-- PARTNERSHIPS -->
  <section class="collabs-container ngm row">
    <h4
      class="col-12 title"
    >{{ 'LANDING_PAGE_B.COMPANY.COLLABS.TITLE' | translate }}</h4>
      <div
        *ngFor="let step of [].constructor(4); index as i"
        class="partner-logo col-4"
        [id]="'partner-logo' + (i + 1)"
        [style.background]="
          'url(/assets/images/landing-pages/collab_logo_b_' +
          (i + 1) +
          '.png) no-repeat center center'
        "
      ></div>
  </section>

  <section class="partnerships-container ngm row">
    <h4
      class="col-12 title"
    >{{ 'LANDING_PAGE_B.COMPANY.PARTNERSHIPS.TITLE' | translate }}</h4>
      <div
        *ngFor="let step of [].constructor(5); index as i"
        class="partner-logo col-2"
        [id]="'partner-logo' + (i + 1)"
        [style.background]="
          'url(/assets/images/landing-pages/partner_logo_b_' +
          (i + 1) +
          '.png) no-repeat center center'
        "
      ></div>
  </section>
</div>