

<app-table
  [dataSource]="dataSource | castSimpleDataSource"
  [columns]="columns"
  [showPager]="false"
  (buttonToggleClickEvent)="onRowDetailClick($event)"
> </app-table>
<tui-pagination
  *ngIf="!!dataSource && dataSource.tablePage.totalPages > 1"
  [index]="currentPage"
  [length]="dataSource.tablePage.totalPages"
  (indexChange)="pagerChange($event)"
></tui-pagination>

<!--ng-template #candidateTemplate let-row="row">
  <a
    tuiLink
    class="fw-bold text-dark cursor-pointer"
    (click)="goToCandidate(row.candidateId)"
    >{{ row.candidateId + " - " + row.candidate }}</a>
</ng-template-->

<ng-template #workerTemplate let-row="row">
  <span><a class="custom-link" (click)="goToCandidate(row.workerArcaId)">{{row.worker}}</a></span>
</ng-template>

<ng-template #clientTemplate let-row="row">
  <span>{{row.client}}</span>
</ng-template>

<ng-template #startTemplate let-row="row">
  <span>{{row.start | date: 'dd/MM/yyyy HH:mm'}} </span>
</ng-template>

<ng-template #endTemplate let-row="row">
  <span>{{row.end | date: 'dd/MM/yyyy HH:mm'}}</span>
</ng-template>

<ng-template #createdAtTemplate let-row="row">
  <span>{{row.createdAt | date: 'dd/MM/yyyy HH:mm'}}</span>
</ng-template>

<ng-template #typeTemplate let-row="row">
  <span>{{row.type}}</span>
</ng-template>

<ng-template #taskTemplate let-row="row">
  <span class="taskText">{{ row.task?.taskDes.toLowerCase() }}</span>
</ng-template>

<ng-template #workPlaceTemplate let-row="row">
  <span class="taskText">
    {{ row.jobLocation?.address }} 
    {{ (row.jobLocation?.municipality ? " - "+('MAPPINGS.municipalities.' + row.jobLocation?.municipality  | translate ) : '')}} 
    {{ row.jobLocation?.district ? " (" + row.jobLocation?.district + ")" : '' }} 
  </span>
</ng-template>



<ng-template #gotoDetailsTemplate let-row="row">
  <span>{{row.type}}</span>
</ng-template>