import { LocalDate } from './../../../../../../../../../core/auth/interfaces/company.model';
import { Sede } from "src/app/core/auth/interfaces/company.model";
import { CostsSimulationData } from "../pages/offert-detail/pages/offert-simulation/services/offert-simulation.service";

export interface OfferResponse {
  number: number;
  insertDate: LocalDate;
  levelCod?: string;
  jobDes?: string;
  taskDes?: string;
  startDate: LocalDate;
  endDate: LocalDate;
  totalPrice: number;
  uuid: string;
  ccnlCode: string;
  ccnlDescription: string;
  ccnlLevelCode: string;
  ccnlLevelDescription: string;
  taskCode: string;
  taskDescription: string;
  jobCode: string;
  jobDescription: string;
  sectorCode: string;
  sectorDescription: string;
  groundOfAppealCod: string;
  positionInail: string;
  commercialOfferState: ProgressPhazesState;
  contactWithMinorType: ContactWithMinorType;
  sedeCommercialOffer?: Sede;
  creationDate: LocalDate;
  endValidityDate: LocalDate;
  costsSimulation?: CostsSimulation
  //FIXME: should't be here in a response as they are used only in requests
  description?: string;
  errorCode?: string;
  method?: string;
  ccnlLevel2?: boolean;
  sedeUuid?: string;
  status?: string;
}

export interface CreateOfferRequest {
  ccnlCode: string;
  ccnlLevelCode: string;
  taskCode: string;
  jobCode: string;
  positionInail: string;
  groundOfAppealCod: string;
  contactWithMinorType: ContactWithMinorType;
  ccnlLevel2: boolean;
  sedeUuid: string;
}

export interface CostsSimulation {
  errorMessage: string;
  prezzo: number;
  input: CostsSimulationData;
}

export interface AcceptOfferRequest {
  commercialOfferUuid: string,
  commercialOfferState: ProgressPhazesState
}

export enum ProgressPhazesState {
  TO_CREATE = "TO_CREATE",
  CREATED = "CREATED",
  ACCEPTED = "ACCEPTED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED", //Deprecated
}
export enum ContactWithMinorType {
  NO = "NO",
  SI = "SI",
  NON_DEFINITO = "NON_DEFINITO",
}
