import { ApiFilterRequest } from './api-filter-request';
import { ApiPageableRequest } from './api-pageable-request';
import { HttpParams } from '@angular/common/http';

export class ApiFiltersRequest {
  filters: ApiFilterRequest[];
  page?: ApiPageableRequest;

  constructor(options: Omit<ApiFiltersRequest, 'toServer' | 'removeFilter'>) {
    this.filters = options.filters;
    this.page = options.page;
  }

  removeFilter(fieldFilterName: string): void {
    const index = this.filters.findIndex((f) => f.field === fieldFilterName);
    this.filters.splice(index, 1);
  }

  toServer(): HttpParams {
    const pageable = this.page.toServer();
    let params = new HttpParams();

    this.filters.forEach((element: { field: string; value: string }) => {
      params = params.set(element.field, element.value?.toString());
    });

    params = params.set('size', pageable.pageSize);
    params = params.set('page', pageable.pageNumber);

    if (pageable.sort) {
      params = params.set('sort', pageable.sort);
    }

    return params;
  }
}
