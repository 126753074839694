import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ContractFinalSummary, ContractInitialSummary } from '../interfaces/contract-summary.model';
import { PaymentInit, PaymentInitRequest } from '../interfaces/payment-init';
import { PaymentOutcome, PaymentOutcomeRequest, PaymentOutcomeRequestNew } from '../interfaces/payment-outcome';

@Injectable()
export class ContractService {
  constructor(private apiClient: ApiClient, private i18n: TranslateService) {}

  getContractSummary(proposalUuid: string): Observable<ContractInitialSummary> {
    return this.apiClient.request('getContractSummary', null, null, {
      proposalUuid,
    });
  }

  getFinalContractSummary(
    proposalUuid: string
  ): Observable<ContractFinalSummary> {
    return this.apiClient.request('getFinalContractSummary', null, null, {
      proposalUuid,
    });
  }

  saveContract(params) {
    return this.apiClient.request('saveContract', params);
  }

  saveExtensionContract(params) {
    return this.apiClient.request('saveExtensionContract', params);
  }

  paymentResultNew(request: PaymentOutcomeRequestNew): Observable<PaymentOutcome> {
    return this.apiClient.request('paymentResultNew', request);
  }

  initPaymentNew(id: number): Observable<PaymentInit> {
    return this.apiClient.request('initPaymentNew', null, null, { id });
  }

}
