import { Component, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { environment } from 'src/environments/environment';
import { OverlayService } from './../../../overlay/overlay.service';
import { PixelWorkerLandingService } from './pixel-worker-landing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-worker-landing',
  templateUrl: './worker-landing.component.html',
  styleUrls: ['./worker-landing.component.scss'],
})
export class WorkerLandingComponent implements OnInit{
  UserType = UserType;
  userType: UserType = UserType.WORKER;
  store = environment.store;
  heroImg = Math.floor(Math.random() * (7 - 1 + 1)) + 1;

  constructor(
    private overlayService: OverlayService,
    private pixelWorkerService: PixelWorkerLandingService,
    private router: Router
  ) {}

  openLoginOverlay() {
    this.overlayService.generalLoginOverlayOpen();
  }

  ngOnInit() {
    this.pixelWorkerService.load();
  }

  toRegister() {
    this.router.navigate(['auth/register/worker'], {
      queryParams: { userType: UserType.WORKER },
    });
  }
}
