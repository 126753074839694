export class TablePage {
  // The number of elements in the page
  size: number = 10;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;

  constructor(options?: Partial<TablePage>) {
    if (options?.size) {
      this.size = options.size;
    }

    if (
      options &&
      options?.pageNumber !== undefined &&
      options?.pageNumber !== null
    ) {
      this.pageNumber = options.pageNumber;
    }
  }
}
